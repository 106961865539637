<template>
    <div class='out_storage'>
        <a-modal 
            :title="title" 
            :confirm-loading="confirmLoading" 
            @ok="confirmEvent"
            v-model:visible="showDialog">
                <m-dialog-content  
                :rules="rules"
                :forms="forms"
                :readOnly="readOnly"
                :formItems="showPop"/>
            </a-modal>
        <m-form
            :formItems="formItems"
            @loadData="loadData(1)"></m-form>
        <m-table 
            :buttons="buttons" 
            :loading="loading" 
            :columns="columns" 
            :data="tableList" 
            :totals="total"
            :currentPage="currentPage"
            @pageChange="loadData($event)"
            @deliverGoods="deliverGoods($event)"
            @detail="detail($event)"></m-table>
    </div>
</template>
<script>
import { computed, reactive,toRefs } from 'vue'
import { isValid, POList, PODetail, deliverGoodsEvent } from '../../utils/api'
import { assginDataToArray, geneIndex, getValueFromObjArr, isEmpty, showMessage } from '@/utils/util'
import { message } from 'ant-design-vue'
export default {
    name: '',
    setup() {
         const state= reactive({
             title: '详情',
             confirmLoading: false,
             showDialog: false,
             buttons: [
                 {text: '详情', eventName: 'detail'},
                 {text: '发货', eventName: 'deliverGoods'}
             ],
             deliverer: {value: '', options: []},
             columns: [
                 {title: '序号', dataIndex: 'index', key: 'index'},
                 {title: '订单编号', dataIndex: 'orderId', key: 'orderId'},
                 {title: '商品名称', dataIndex: 'name', key: 'name'},
                 {title: '发货状态', dataIndex: 'sendStatusText', key: 'payStatusText'},
                 {title: '商品单价（元）', dataIndex: 'productPrice', key: 'productPrice'},
                 {title: '下单个数', dataIndex: 'buyCounts', key: 'buyCounts'},
                 {title: '支付状态', dataIndex: 'payStatusText', key: 'payStatusText'},
                 {title: '下单时间', dataIndex: 'createTime', key: 'createTime'},
                 {title: '操作', dataIndex: 'manage', key: 'manage', slots: {customRender: 'actions'}}
             ],
             formItems: [
                //  {type: 'input', label: '代理姓名', value: undefined},
                 {type: 'selector', key: 'value', options: [{label: '待付款', value: 1}, {label: '待收货', value: 2}, {label: '已完成', value: 3}, {label: '已取消', value: 4}], label: '订单状态', value: undefined},
                 {type: 'selector', key: 'value', options: [{label: '彩票', value: 1}, {label: '设备', value: 0}], label: '产品类型', value: undefined},
                 {type: 'selector', key: 'value', options: [{label: '体彩', value: 1}, {label: '福彩', value: 0}], label: '彩种', value: undefined},
                 {type: 'button', eventName: 'loadData', label: '搜索'},
                //  {type: 'button', eventName: 'refresh', label: '手动生成订单'}
             ],
             checkForms: [
                 {label: '代理状态', type: 'selector', value: '', key: 'value', prop: 'status', options: [{labelName: '待处理', value: 0}, {labelName: '通过', value: 1}, {labelName: '驳回', value: 2}]},
                 {label: '留言', type: 'input', value: '', prop: 'remark'}
             ],
             popForms: [
                 {label: '订单编号', prop: 'orderId', value: '', type: 'input'},
                 {label: '支持彩种', prop: 'gameTypeText', value: '', type: 'input'},
                 {label: '商品名称', prop: 'name', value: '', type: 'input'},
                 {label: '商品单价（元）', prop: 'productPrice', value: '', type: 'input'},
                 {label: '下单个数', prop: 'buyCounts', value: '', type: 'input'},
                 {label: '团队人数', prop: 'teamAgentCount', value: '', type: 'input'},
                 {label: '支付状态', prop: 'payStatusText', value: '', type: 'input'},
                 {label: '发货状态', prop: 'sendStatusText', value: '', type: 'input'},
                 {label: '交易状态', prop: 'tradeStatusText', value: '', type: 'input'},
                 {label: '运费（元）', prop: 'freightPrice', value: '', type: 'input'},
                 {label: '实付款（元）', prop: 'finalPrice', value: '', type: 'input'},
                 {label: '下单时间', prop: 'createTime', value: '', type: 'input'},
                 {label: '收货人姓名', prop: 'receiveName', value: '', type: 'input'},
                 {label: '收货人电话', prop: 'phone', value: '', type: 'input'},
                 {label: '收货人地址', props: ['province', 'city', 'county'], value: '', type: 'input'},
                 {label: '收货人街道', prop: 'townStreet', value: '', type: 'input'},
                 {label: '收货人详细信息', prop: 'address', value: '', type: 'input'},
            ],
            dilverForms: [
                {label: '快递公司编号', prop: 'shipperCode', value: '', type: 'input'},
                {label: '快递公司名称', prop: 'logisticsName', value: '', type: 'input'},
                {label: '物流类型', prop: 'logisticsType', value: '', type: 'selector', options: [{labelName: '发货物流', value: 0}, {labelName: '售后物流', value: 1}]},
                {label: '快递公司单号', prop: 'logisticCode', value: '', type: 'input'},
                {label: '快递服务电话', prop: 'phone', value: '', type: 'input'},
            ],
            popType: 0, // 0: detail 1: deliverGoods
            forms: {timeout: ''},
            rules: {timeout: [{ required: true, message: '请输入超时时间', trigger: 'blur'}]},
            tableList: [],
            currentPage: 1,
            pageSize: 10,
            total: 0,
            selectedIDs: [],
            loading: false,
            status: 0,
            detail: {},
            rowInfo: {},
        })
        const readOnly = computed(() => state.popType == 0)
        const showPop = computed(() => state.popType == 0 ? state.popForms : state.dilverForms)
        const lotteryTypes = [
            [0, '福彩'],
            [1, '体彩']
        ]
        const deliverStatus = [
            [0, '初始化'],
            [1, '待发货'],
            [2, '已发货'],
            [3, '确认收货']
        ]
        async function  loadData(pageNum = null) {
            try {
                state.loading = true
                state.currentPage = pageNum || state.currentPage
                const params = {
                    pageNum:  state.currentPage,
                    pageSize: state.pageSize,
                    orderStatus: state.formItems[0].value,
                    productType: state.formItems[1].value,
                    gameType: state.formItems[2].value
                }
                const result = await POList(params)
                state.loading = false
                if(isValid(result)) {
                    state.total = result.data.total
                    state.tableList = geneIndex(result.data.orderList, state.pageSize, state.currentPage)
                    state.tableList.forEach(ele => {
                        const ps = ele.payStatus
                        ele.sendStatusText = getValueFromObjArr(deliverStatus, ele.sendStatus)
                         ele.payStatusText = ps == 1 ? '未支付'
                                      : ps == 2 ? '支付中'
                                      : ps == 3 ? '支付成功'
                                      : ps == 4 ? '支付失败' : '待支付'
                    })
                }
            } catch(e) {
                state.loading = false
                console.error(e)
            }
        }
        loadData()
        async function detail(event) {
            try {
                state.popType = 0
                const result = await  PODetail(event.orderId)
                if (isValid(result)) {
                    const ele = result.data 
                    const ss = ele.sendStatus, ts = ele.tradeStatus, ps = ele.payStatus 
                    ele.sendStatusText = ss == 0 ? '快递初始状态'
                                        :ss == 1 ? '待发货'
                                        :ss == 2 ? '已发货'
                                        :ss == 3 ? '确认收货' : ''
                    ele.tradeStatusText = ts == 0 ? '进行中' : '已完成'
                    ele.payStatusText = ps == 1 ? '未支付'
                                      : ps == 2 ? '支付中'
                                      : ps == 3 ? '支付成功'
                                      : ps == 4 ? '支付失败' : '待支付'
                    ele.gameTypeText = getValueFromObjArr(lotteryTypes, ele.gameType)
                    console.log(result.data)
                    state.popForms = assginDataToArray(result.data, state.popForms)
                    state.showDialog = true
                }
            } catch(e) {
                console.error(e)
            }
        }

        function deliverGoods(info) {
            if (info.sendStatus == 2 || info.sendStatus == 3) {
                showMessage('订单已发货，不可重复操作', 'wran')
                return
            }
            state.popType = 1
            state.rowInfo = info
            state.showDialog = true
        }

        async function confirmEvent() {
            try {
                if (readOnly.value) {
                    loadData()
                    state.showDialog = false
                } else {
                    const item = state.dilverForms
                    if (isEmpty(item)) {
                        return
                    }
                    const params = {
                        orderId: state.rowInfo.orderId,
                        shipperCode: item[0].value,
                        logisticsName: item[1].value,
                        logisticsType: item[2].value?.value,
                        logisticCode: item[3].value,
                        phone: item[4].value
                    }
                    const result = await deliverGoodsEvent(params)
                    if (result.code == 0) {
                        showMessage('发货成功')
                        state.showDialog = false
                    }
                }
            } catch(e) {
                console.error(e)
            }
        }
       
        function closeDialog() {
            loadData()
            state.showDialog = false
        }
        
        return {
            ...toRefs(state),
            detail,
            loadData,
            closeDialog,
            deliverGoods,
            showPop,
            readOnly,
            confirmEvent
        }
    },
}
</script>
<style lang='scss' scoped>
</style>